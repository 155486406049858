const getPeriodKey = (p) => p.count + p.type;
const getPeriodLabel = (p, t) => {
    let label = '';
    if (p.notation === 'short') {
        switch (p.type) {
            case 'day':
                label = t('date_time.periods.short.day', { ns: 'common', count: p.count });
                break;
            case 'week':
                label = t('date_time.periods.short.week', { ns: 'common', count: p.count });
                break;
            case 'month':
                label = t('date_time.periods.short.month', { ns: 'common', count: p.count });
                break;
            case 'year':
                label = t('date_time.periods.short.year', { ns: 'common', count: p.count });
                break;
            case 'all':
                label = t('date_time.periods.all', { ns: 'common' });
                break;
            case 'all_time':
                label = t('date_time.periods.all_time', { ns: 'common' });
                break;
            default:
        }
    }
    else if (p.notation === 'long') {
        switch (p.type) {
            case 'day':
                label = t('date_time.periods.long.day', { ns: 'common', count: p.count });
                break;
            case 'week':
                label = t('date_time.periods.long.week', { ns: 'common', count: p.count });
                break;
            case 'month':
                label = t('date_time.periods.long.month', { ns: 'common', count: p.count });
                break;
            case 'year':
                label = t('date_time.periods.long.year', { ns: 'common', count: p.count });
                break;
            case 'all':
                label = t('date_time.periods.all', { ns: 'common' });
                break;
            case 'all_time':
                label = t('date_time.periods.all_time', { ns: 'common' });
                break;
            default:
        }
    }
    return label;
};
export { getPeriodKey, getPeriodLabel, };
