import { jsx as _jsx } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
/* eslint-disable react/jsx-props-no-spreading */
import MuiPopper from '@mui/material/Popper';
import MuiPaper from '@mui/material/Paper';
import MuiMenuList from '@mui/material/MenuList';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { forwardRef, useMemo, useRef, useState, } from 'react';
import styled from '@emotion/styled';
import VirtualList from '@shared/ui/misc/VirtualList';
import DropdownMenuItem from './MenuItem';
import Divider, { DIVIDER_HEIGHT } from './Divider';
import DropdownMenuHeader from './MenuHeader';
import DropdownMenuBackButton, { MENU_BACK_BTN_SIZES } from './MenuBackButton';
import Grow from './Grow';
const MuiPaperStyled = styled(MuiPaper)((props) => ({
    marginTop: props.theme.spacing_sizes.s,
    border: `1px solid ${props.theme.customColors.menu.border}`,
    boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.08)',
    '&::-webkit-scrollbar': {
        borderRadius: 4,
        width: 6,
    },
    '&::-webkit-scrollbar-thumb': {
        background: '#C1C8CD',
        width: 4,
        borderRadius: 4,
    },
}));
const DropdownMenuVirtualComponent = ({ isOpen = false, maxItemsCount = 10, items, nestedVariants = {}, size = 'medium', role = 'list', onClose, keepMounted = true, disablePortal = false, transition = false, paperStyles = undefined, listStyles = undefined, popperStyles = undefined, placement = 'bottom', removePadding = false, zIndex = undefined, closeMenuAfterClick = undefined, menuWidth = 150, menuHeight = 300, }, anchorRef) => {
    const [activeKeys, setActiveKeys] = useState([]);
    const listRef = useRef(null);
    function handleItemClick(handler) {
        return (event, key) => {
            setActiveKeys((prev) => [...prev, key]);
            if (handler) {
                handler(event, key);
            }
            if (closeMenuAfterClick && onClose) {
                onClose(event.nativeEvent);
            }
        };
    }
    const goBack = () => {
        if (activeKeys === null || activeKeys === void 0 ? void 0 : activeKeys.length) {
            setActiveKeys((prev) => {
                const newValue = prev.slice(0, prev.length - 1);
                return newValue;
            });
        }
    };
    const { items: menuItems, totalHeight, itemSizes } = useMemo(() => {
        var _a, _b;
        const currentActiveKey = activeKeys[activeKeys.length - 1];
        const activeOption = nestedVariants[currentActiveKey];
        let height = 0;
        const sizes = [];
        let itemsCount = 0;
        if (currentActiveKey && ((_a = activeOption === null || activeOption === void 0 ? void 0 : activeOption.children) === null || _a === void 0 ? void 0 : _a.length)) {
            const options = ((_b = activeOption === null || activeOption === void 0 ? void 0 : activeOption.children) === null || _b === void 0 ? void 0 : _b.map((item, index) => {
                if (itemsCount < maxItemsCount) {
                    height += item.itemHeight;
                    sizes.push(item.itemHeight);
                    itemsCount += 1;
                }
                return (_createElement(DropdownMenuItem, Object.assign({}, item, { key: item.itemKey || `element-${index}`, size: item.size || size, onClick: handleItemClick(item.onClick) })));
            })) || [];
            if (typeof activeOption.header !== 'undefined') {
                if (itemsCount < maxItemsCount) {
                    height += activeOption.headerHeight || 0;
                    sizes.push(activeOption.headerHeight || 0);
                    itemsCount += 1;
                }
                options.unshift(_jsx(DropdownMenuHeader, { children: activeOption.header }, `header-${currentActiveKey}`));
            }
            if (activeOption.showBackButton) {
                if (itemsCount < maxItemsCount) {
                    height += DIVIDER_HEIGHT;
                    sizes.push(DIVIDER_HEIGHT);
                    height += MENU_BACK_BTN_SIZES[size] || 0;
                    sizes.push(MENU_BACK_BTN_SIZES[size] || 0);
                    itemsCount += 1;
                }
                options.push(_jsx(Divider, {}, `divider-back-${currentActiveKey}`));
                options.push(_jsx(DropdownMenuBackButton, { onClick: goBack, itemKey: `back-${currentActiveKey}`, size: size }, `back-${currentActiveKey}`));
            }
            return { items: options, totalHeight: height, itemSizes: sizes };
        }
        return {
            items: items.map((item, index) => {
                if (itemsCount < maxItemsCount) {
                    height += item.itemHeight;
                    sizes.push(item.itemHeight);
                    itemsCount += 1;
                }
                return (_createElement(DropdownMenuItem, Object.assign({}, item, { key: item.itemKey || `element-${index}`, itemKey: item.itemKey, size: item.size || size, onClick: handleItemClick(item.onClick), removePadding: item.removePadding || removePadding })));
            }),
            totalHeight: height,
            itemSizes: sizes,
        };
    }, [nestedVariants, activeKeys, items, size, maxItemsCount]);
    const handleMenuClose = (ev) => {
        var _a;
        if ((anchorRef === null || anchorRef === void 0 ? void 0 : anchorRef.current) &&
            ((_a = anchorRef === null || anchorRef === void 0 ? void 0 : anchorRef.current) === null || _a === void 0 ? void 0 : _a.contains(ev.target))) {
            setTimeout(() => setActiveKeys([]), 500);
            return;
        }
        if (onClose) {
            onClose(ev);
        }
        setTimeout(() => setActiveKeys([]), 500);
    };
    return (_jsx(MuiPopper, { open: isOpen, popperOptions: {
            placement,
        }, style: Object.assign(Object.assign({}, (popperStyles || {})), { zIndex }), 
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line react/destructuring-assignment
        anchorEl: anchorRef.current, placement: placement, role: role, keepMounted: keepMounted, transition: transition, disablePortal: disablePortal, children: ({ TransitionProps, placement: growPlacement }) => {
            var _a;
            return (_jsx(Grow, Object.assign({}, TransitionProps, { timeout: 500, style: {
                    transformOrigin: growPlacement === 'bottom-start' ? 'left top' : 'left bottom',
                }, children: _jsx(MuiPaperStyled, { style: Object.assign(Object.assign({}, (paperStyles || {})), { visibility: isOpen ? 'visible' : 'hidden', overflowY: 'auto', width: menuWidth }), children: _jsx(ClickAwayListener, { onClickAway: handleMenuClose, children: _jsx(MuiMenuList, { style: Object.assign({}, (listStyles || {})), ref: listRef, children: ((_a = (listRef)) === null || _a === void 0 ? void 0 : _a.current) !== null && (_jsx(VirtualList, { ref: listRef, listHeight: menuHeight > totalHeight ? totalHeight : menuHeight, itemSizes: itemSizes, children: menuItems })) }) }) }) })));
        } }));
};
const DropdownMenuVirtual = forwardRef(DropdownMenuVirtualComponent);
export default DropdownMenuVirtual;
