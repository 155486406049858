const GeneralProfitPeriodsPresetShort = [
    {
        count: 30,
        type: 'day',
        notation: 'short',
    },
    {
        count: 90,
        type: 'day',
        notation: 'short',
    },
    {
        count: 180,
        type: 'day',
        notation: 'short',
    },
    {
        count: 1,
        type: 'year',
        notation: 'long',
    },
];
const GeneralPortfolioInformationPeriodsPreset = [
    {
        count: 0,
        type: 'all',
        notation: 'long',
    },
    {
        count: 7,
        type: 'day',
        notation: 'short',
    },
    {
        count: 30,
        type: 'day',
        notation: 'short',
    },
    {
        count: 90,
        type: 'day',
        notation: 'short',
    },
];
export { GeneralProfitPeriodsPresetShort, GeneralPortfolioInformationPeriodsPreset, };
