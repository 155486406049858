import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { MenuItem } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Select from '@shared/ui/inputs/Select';
import { ContainerColumn } from '@components/styled';
import { getPeriodKey, getPeriodLabel as getPeriodLabelUtil, } from './utils';
const Container = styled(ContainerColumn)(() => ({
    width: 'auto',
    height: 'auto',
}));
const DatePeriodMenu = ({ currentPeriod, periods, onPeriodChange, size = 'small', }) => {
    const { t } = useTranslation();
    const periodsMap = useMemo(() => {
        const map = {};
        periods.forEach((p) => {
            map[getPeriodKey(p)] = p;
        });
        return map;
    }, [periods]);
    const onSelectChange = (e) => {
        const periodKey = e.target.value;
        onPeriodChange(periodsMap[periodKey]);
    };
    const getPeriodLabel = useCallback((p) => getPeriodLabelUtil(p, t), [t]);
    return (_jsx(Container, { children: _jsx(Select, { size: size, value: getPeriodKey(currentPeriod), onChange: onSelectChange, selectVariant: 'date-period', highlightSelected: true, children: periods.map(p => (_jsx(MenuItem, { value: getPeriodKey(p), children: getPeriodLabel(p) }, getPeriodKey(p)))) }) }));
};
export default DatePeriodMenu;
